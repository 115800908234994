import React from 'react';

export default () => (
	<div className='Client'>
		<h4>
    Client Representation
    </h4>
		<p>
			CN Agency provides all the necessary services to further an athletes abilities and giving them a platform
      to be seen. We offer services such as sports marketing, player management, personal consultations, NIL education...
      ORR We give players the tools and platform to connect and strategically plan out future plans
    </p>


	</div>


);
